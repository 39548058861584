<template>
  <div class="container footer--directory">
    <div class="footer--title mb-3">
      {{ $t('footer.directory.header') }}
    </div>
    <div class="d-flex flex-wrap">
      <div class="d-flex flex-wrap mb-3 footer--nav-wrapper">
        <router-link
          :to="property.url"
          class="footer--nav mb-2"
          v-for="property in properties"
          :key="property.url"
        >
          <span v-if="property.isAll">
            {{ `${$t('home.seeAll')} ${property.propertyType} ${$t('general.sold')} >` }}
          </span>
          <span v-else>
            {{
              `${$t('general.sold')} ${property.propertyType} ${$t('general.in')} ${property.name}`
            }}
          </span>
        </router-link>
        <router-link to="/cari/semua-tipe-properti" class="footer--nav mb-2">
          {{ `${$t('home.seeAll')} ${$t('general.property')} ${$t('general.sold')} >` }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    properties: [
      {
        url: '/cari/rumah/?regions=31_Dki%20Jakarta',
        name: 'Jakarta',
        propertyType: 'Rumah',
      },
      {
        url: '/cari/rumah/?regions=3674_Kota%20Tangerang%20Selatan',
        name: 'Tangerang Selatan',
        propertyType: 'Rumah',
      },
      {
        url: '/cari/rumah/bekasi/',
        name: 'Bekasi',
        propertyType: 'Rumah',
      },
      {
        url: '/cari/apartemen/?regions=31_Dki%20Jakarta',
        name: 'Jakarta',
        propertyType: 'Apartemen',
      },
      {
        url: '/cari/apartemen/tangerang/',
        name: 'Tangerang',
        propertyType: 'Apartemen',
      },
      {
        url: '/cari/pabrik/Bekasi/',
        name: 'Bekasi',
        propertyType: 'Pabrik',
      },
      {
        url: '/cari/tanah-residensial/Bekasi/?property_type_id=5&property_type_id=6',
        name: 'Bekasi',
        propertyType: 'Tanah',
      },
      {
        url: '/direktori/rumah',
        name: 'Indonesia',
        propertyType: 'Rumah',
        isAll: true,
      },
      {
        url: '/direktori/apartemen',
        name: 'Indonesia',
        propertyType: 'Apartemen',
        isAll: true,
      },
    ],
  }),
};
</script>

<style lang="scss" scoped></style>
